import React from 'react';

const Youtube = ({ videoId, title, videoWidth, videoHeight }) => {

  const videoUrl = `https://www.youtube.com/embed/${videoId}`;
  const videoTitle = title || 'Ninenote\'s Video';
  const width = videoWidth || '100%';
  const height = videoHeight || 'auto';

  return (
      <iframe loading="lazy" width={width} height={height} src={videoUrl} title={videoTitle} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  );
};

export default Youtube;
