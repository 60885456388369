import React from 'react'
import Highlight, {defaultProps} from 'prism-react-renderer'
// import theme from 'prism-react-renderer/themes/duotoneDark';
// import theme from 'prism-react-renderer/themes/duotoneLight';
// import theme from 'prism-react-renderer/themes/github';
// import theme from 'prism-react-renderer/themes/nightOwl';
// import theme from 'prism-react-renderer/themes/nightOwlLight';
// import theme from 'prism-react-renderer/themes/oceanicNext';
// import theme from 'prism-react-renderer/themes/okaidia';
import theme from 'prism-react-renderer/themes/palenight';
// import theme from 'prism-react-renderer/themes/shadesOfPurple';
// import theme from 'prism-react-renderer/themes/synthwave84';
// import theme from 'prism-react-renderer/themes/ultramin';
// import theme from 'prism-react-renderer/themes/vsDark';
// import theme from 'prism-react-renderer/themes/vsLight';

import styled from 'styled-components';

const Pre = styled.pre`
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;
  overflow: scroll;
`;

const Line = styled.div`
  display: table-row;
`;

const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1em;
  user-select: none;
  opacity: 0.5;
`;

const LineContent = styled.span`
  display: table-cell;
`;

const CodeBlock = (props) => {
    const className = props.children.props.className || 'language-bash'
    const matches = className.match(/language-(?<lang>.*)/)
  return (
    <Highlight {...defaultProps} code={props.children.props.children.trim()} language={
        matches && matches.groups && matches.groups.lang
          ? matches.groups.lang
          : ''
      }
      theme={theme}>
      {({className, style, tokens, getLineProps, getTokenProps}) => (
        <Pre className={className} style={{...style, padding: '20px'}}>
          {tokens.map((line, i) => (
            <Line key={i} {...getLineProps({ line, key: i })}>
              <LineNo>{i + 1}</LineNo>
              <LineContent>
              <div key={i} {...getLineProps({line, key: i})}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({token, key})} />
                ))}
              </div>
              </LineContent>
            </Line>
          ))}
        </Pre>
      )}
    </Highlight>
  )
};

export default CodeBlock;
