import styled from 'styled-components';

const ImageWrapper = styled.div`
  margin-bottom: 2rem;
  padding: 0.8rem;
  border: 1px solid #ccc;
  background-color: #efefef;
  p.caption {
    margin: 0;
    font-size: 0.85rem;
    text-align: center;
  }
`;

export {
  ImageWrapper,
}
