import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Layout from '../components/layout'
import Seo from '../components/seo'
import { LocalImage } from '../components/mdx/image';
import Comment from '../components/comment';
import CodeBlock from '../components/mdx/codeBlock';
import ArticleMeta from '../components/articleMeta';
import Youtube from '../components/mdx/youtube';
import { Link } from 'gatsby';

const Post = ({ data }) => {
  const { frontmatter, slug, body } = data.mdx;
  const { title, description, date, category, tags } = frontmatter;
  const components = {
    LocalImage,
    Youtube,
    pre: CodeBlock,
    Link,
  };

  return (
    <Layout frontmatter={frontmatter} slug={slug}>
      <Seo title={title} description={description} />
      <h1>{title}</h1>
      <ArticleMeta category={category} date={date} tags={tags} />
      <MDXProvider components={components} >
        <MDXRenderer frontmatter={frontmatter}>
          {body} 
        </MDXRenderer>
      </MDXProvider>
      <p>สอบถาม ติชม เสนอแนะ ได้ที่ช่อง comment ด้านล่างเลยครับ</p>
      <Comment slug={slug} title={title} />
    </Layout>
  );
};

export default Post;

export const query = graphql`
   query ($id: String) {
     mdx(id: {eq: $id}) {
       frontmatter {
         title
         description
         heroImage {
           childImageSharp {
             gatsbyImageData(layout: CONSTRAINED)
           }
         }
         date(formatString: "MMMM Do, YYYY")
         category
         tags
         author
         localImages {
           childImageSharp {
             gatsbyImageData(layout: CONSTRAINED)
           }
         }
       }
       body
       slug
     }
   }
`;
