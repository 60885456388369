import React from 'react';
import { Disqus } from 'gatsby-plugin-disqus';


const Comment = ({ slug, title }) => {

  const config = {
    url: `https://ninenote.net/${slug}`,
    title,
  }

  return (
    <Disqus config={config} />
  );
}

export default Comment;
