import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { ImageWrapper } from './styled';

const LocalImage = ({ localImage, alt, caption, noframe=true }) => {

  const imageData = getImage(localImage);
  const showBorder = (noframe)? {
    background: 'none',
    border: 'none',
  }: {}; 

  return (
    <ImageWrapper style={showBorder}>
      <GatsbyImage alt={alt} image={imageData} />
      <p className="caption">{caption}</p>
    </ImageWrapper>
  );  
}

export { LocalImage };

